.carousel {
  position: relative;
  margin: 20px 0;
  max-height: 800px;
  overflow: hidden; 
  text-align: center; 
}

.carousel img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
  max-height: 100vh; 
}

.carousel .carousel-control {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  transition: background-color 0.2s, transform 0.2s;
  position: absolute;
  cursor: pointer;
}

.carousel .carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}


.carousel .carousel-control-prev {
  left: 10px;
  top: 50%; 
  transform: translateY(-50%);
}

.carousel .carousel-control-next {
  right: 10px;
  top: 50%; 
  transform: translateY(-50%);
}

.overlay-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.overlay-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
