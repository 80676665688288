.Title {
    font-family: 'Lobster Two', cursive !important;
    color: #333;
    font-size: 32px !important;
}

@media (max-width: 800px) {
  .Title {
      font-size: 24px !important;
  }
}

.navbar {
    display: flex;
    align-items: center;
    padding: 10px 20px; 
  }
  
  .logo {
    max-width: 100px; 
    height: auto; 
    margin-right: 5px; 
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: black !important;
  }

  

  